@import url('https://fonts.googleapis.com/css?family=Gugi');

.signin {
  background-color: #F5F5F5;
}

.checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.inputcheckbox {
  display: none;
}
 

.logouser {
  background-color: #FFF;
  border-radius: 50%;
  border: 1px solid #FFF;
}

.signin-form-header {
  background-color: #F5F5F5;
  font-family: 'Gugi', cursive;
}

.signin-form-header > h6 {
  font-size: 1.6em;
}

.signin-form-body {
  background-color: #FFF;
  border: 1px solid #DBDBDB;
  border-radius: 1px;
  padding: 1em;
  box-sizing: border-box;
}

.newtask {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 1em;
  background-color: #F5F5F5;
}

.newtask-aside {
  width: 25%;
  box-sizing: border-box;
}

.newtask-body {
  box-sizing: border-box;
  margin-top: 3em;
  margin-bottom: 1em;
  width: 60%;
  margin: 1.5em;
}

.navegador {
  background-color: #FFF;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222;
}

.navegador-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: .5em;
  box-sizing: border-box;
}

.navegador-rigth {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: .5em;
  box-sizing: border-box;
}

.logo-img {
  height: 40px;
  width: 40px;
}

.logo-figcaption {
  margin-left: .25em;
  font-size: 1.6em;
  color: #FFF;
}


.link {
  margin: .5em;
}

.navegador-mobile{
  display: none;
}

.taskitem-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 620px) {

  .newtask {
    width: 100%;
  }

  .newtask-body {
    width: calc(100% - 2em);
    margin: 1em;
  }

  .newtask-aside {
    width: calc(100% - 2em);
    margin: 1em;
  }

  .navegador {
    max-width: 100%;
    overflow-x: scroll;
  }

  .checkboximg {
    order: 2;
  }

  .checkboxtitle {
    order: 1;
  }

  .newtask-body {
    order: 1;
  }

  .newtask-aside {
    width: 100%;
    order: 2;
  }

}

.roadmap {
  background-color: #F5F5F5;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;
}

.signin-form-header h6 {
  font-weight: bold;
}

.roadmap-body {
  min-width: calc(60% - 2em);
  margin: 1em;
}

.roadmap-aside {
  min-width: calc(20% - 2em);
  margin: 1em;
}

.nav-aside {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 2em;
  padding: 1em;
  box-sizing: border-box;
}
.nav-aside-item {
  margin: .25em;
  padding: .5em;
  border: 1px solid #DDD;
  background-color: #FFF;
}

.pie {
  background-color: #F5F5F5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
}

.pie-body {
  width: 100%;
  padding: .5em;
  text-align: center;
}

.vehicles {
  max-width: 80%;
  min-height: 100vh;
}

.monitor {
  min-height: 90vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: #DBDBDB;
}

.monitor-header {
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  text-align: center;
}

.monitor-body {
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  
}

.monitor-body-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  margin: 1em;
}

.monitor-body-item-item {
  background-color: #FFF;
  box-sizing: border-box;
  padding: 1em;
  border-bottom: 1px solid #DBDBDB;
}

.monitor-body-item-body {
  list-style: none;
  box-sizing: border-box;
  padding: 1em;
}

.monitor-body-item-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
}

.monitor-body-item-item-header > h5 {
  width: 90%;
  box-sizing: border-box;
  margin-right: 1em;
}

.monitor-body-item-item-header > small {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  box-sizing: border-box;
  border: 1px solid white;
}

.completada {
  background-color: #5cb85c;
}

.completada_sin_terminar {
  background-color: #5bc0de;
}

.retrasada {
  background-color: #d9534f;
}

.pendiente {
  background-color: #f0ad4e;
}

.cancelada {
  background-color: #292b2c;
}

.horaria {
  background-color: #FFF;
}

.ahoraria {
  background-color: #292b2c;
}

.monitor-footer {
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.monitor-footer-item {
  padding: .5em;
  margin: 1em;
}

.roadmap-list-item{
  color: #FFF;
}

.roadmap-list-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.roadmap-list-item-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.roadmap-list-item-body-right {
  margin-left: 1em;
  margin-top: 1em;
  box-sizing: border-box;
  max-width: 300px;
}

.roadmap-list-item-body-left {
  max-width: 40%;
}

@media only screen and (max-width: 640px) {
  .roadmap-list-item-body-left {
    max-width: 100%;
  }
  .roadmap-list-item-body-right{
    max-width: 100%;
  }
}